<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, <b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>

            <div class="row" style="margin:0px">
                <div class="col-sm-6" style="padding:10px;">
                    <div class="shadow box-item">
                        <div class="row" style="margin-bottom:12px">
                            <div class="col-sm-6" style="font-size:14px;align-self: center;">Administrasi KBM</div>
                            <div class="col-sm-6" style="text-align: right;"><button class="btn" style="background-image: linear-gradient(#EC008C, #FC6767);color: white;">Lihat Semua</button></div>
                        </div>
                        <div class="col-md-12 form-group" style="padding: 0px;">
                            <input id="gender" class="form-control" v-model="form.kbmActivity" type="text" name="i_gender" placeholder="Cari aktifitas lain" autocomplete="off" />
                        </div>
                        <div style="padding: 30px;text-align: center;">
                            <img src="@/assets/img/notfound.png" alt="none" width="99" height="99">
                            <br>
                            <br>
                            <span style="color:#363636">
                                Tidak ada data administrasi KBM yang outstanding
                            </span>
                        </div>
                    </div>
                </div>
                
                <div class="col-sm-6" style="padding:10px;">
                    <div class="shadow box-item">
                        <div class="row" style="margin-bottom:12px">
                            <div class="col-sm-6" style="font-size:14px;align-self: center;">Administrasi Inventaris</div>
                            <div class="col-sm-6" style="text-align: right;"><button class="btn" style="background-image: linear-gradient(#EC008C, #FC6767);color: white;">Lihat Semua</button></div>
                        </div>
                        <div class="col-md-12 form-group" style="padding: 0px;">
                            <input id="gender" class="form-control" v-model="form.kbmActivity" type="text" name="i_gender" placeholder="Cari aktifitas lain" autocomplete="off" />
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="callout callout-danger">
                                    <div class="row">
                                        <div class="col-sm-8">
                                          <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">Pendaftaran Polri</h5>
                                          <p style="font-size:14px;font-weight:400;color: #9590A0;">Admin | 10/06/2021</p>
                                          <p>Rp 2.000.000.-</p>
                                        </div>
                                        <div class="col-sm-4" style="text-align: -webkit-center;">
                                            <div style="text-align:center;width:100%;background: green;color:white;margin-bottom: 8px">
                                                Disetujui
                                            </div>
                                            <div class="event-circle">
                                                <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="callout callout-warning">
                                    <div class="row">
                                        <div class="col-sm-8">
                                          <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">Pendaftaran Polri</h5>
                                          <p style="font-size:14px;font-weight:400;color: #9590A0;">Admin | 10/06/2021</p>
                                          <p>Rp 2.000.000.-</p>
                                        </div>
                                        <div class="col-sm-4" style="text-align: -webkit-center;">
                                            <div style="text-align:center;width:100%;background: #FCA702;color:white;margin-bottom: 8px">
                                                Pengajuan
                                            </div>
                                            <div class="event-circle" style="background:#FCA702">
                                                <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="callout callout-warning">
                                    <div class="row">
                                        <div class="col-sm-8">
                                          <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">Pendaftaran Polri</h5>
                                          <p style="font-size:14px;font-weight:400;color: #9590A0;">Admin | 10/06/2021</p>
                                          <p>Rp 2.000.000.-</p>
                                        </div>
                                        <div class="col-sm-4" style="text-align: -webkit-center;">
                                            <div style="text-align:center;width:100%;background: green;color:white;margin-bottom: 8px">
                                                Disetujui
                                            </div>
                                            <div class="event-circle" style="background:#FCA702">
                                                <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="right-side">
                <span style="font-size:18px;color: #363636;">Tool Administrasi</span>
                <div class="row" style="margin-top:12px">
                    
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/jadwal-kelas">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-alt fa-2x"></i><br><br> Jadwal Kelas
                        </div>
                        </router-link>
                    </div>
                    
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/jadwal-guru">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-alt fa-2x"></i><br><br> Jadwal Tentor
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/guru">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="'fas fa-user fa-2x"></i><br><br> Daftar Guru
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/buku-tamu">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-user-shield fa-2x"></i><br><br> Buku Tamu
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/jadwal-kelas">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-user-plus fa-2x"></i><br><br> Rekrutmen
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/inventaris">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-alt fa-2x"></i><br><br> Rekap Inventaris
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/artikel">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-newspaper fa-2x"></i><br><br> Artikel
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/siswa-registrasi">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-user fa-2x"></i><br><br> Registrasi Siswa
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/absensi-siswa">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-check fa-2x"></i><br><br> Absensi Siswa
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/absensi-jasmani">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-check fa-2x"></i><br><br> Absensi Jasmani
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/absensi-guru">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-check fa-2x"></i><br><br> Absensi Guru
                        </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="right-side">
                <span style="font-size:18px;color: #363636;">Kalender Akademik</span>
                <div class="row">
                    <div id="myCal"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth} from '@/libs/hxcore';
    import Calendar from 'color-calendar';
    import "color-calendar/dist/css/theme-glass.css";
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';

    export default {
        name: 'AdministrasiDashboard',
        components: {
            IconifyIcon
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                form: {
                    kbmActivity : ''
                },
                name : '',
                imgUrl : ''
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            console.log('user', info)
            if(info)
            {
              this.name = info.display_name;
              this.imgUrl = info.img_url;
            }
            else{
              this.imgUrl = '/dist/img/user.jpeg';
            }
        },
        mounted() {
            new Calendar({
              id: "#myCal",
              theme: "glass",
              eventsData: [
                    {
                        start: '2021-06-17T06:00:00',
                        end: '2021-06-18T20:30:00',
                        name: 'Blockchain 101'
                    },
                ]
            });
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 32px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .shadow-low {
        box-shadow: 0 0.3rem 0.3rem rgb(18 38 63 / 7%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 12px 22px;
        margin-bottom: 12px;
        /*height: 100%;*/
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .tool-box {
        /*width:48px;
        height: 48px;*/
        background: #C8372D;
        border-radius: 10px;
    }

    .callout-danger {
        background: #EBEBEB;
    }

    .callout.callout-danger {
        border-left: 8px #C8372D solid;
        border-radius: 10px;
    }

    .callout-warning {
        background: #EBEBEB;
    }

    .callout.callout-warning {
        border-left: 8px #FCA702 solid;
        border-radius: 10px;
    }

    .calendar__body.color-calendar {
        font-size: 0.7rem !important;
    }

    .box-item {
        width:100%;
        min-height: 262px;
        background:white;
        padding: 16px;
        padding-top: 24px;
        border-radius: 20px;
        margin-bottom: 12px;
    }
</style>